<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>运营系统管理方</el-breadcrumb-item>
                <el-breadcrumb-item>模版路径1</el-breadcrumb-item>
                <el-breadcrumb-item>模版路径2</el-breadcrumb-item>
            </el-breadcrumb>
            <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
            </SearchForm>
            <template v-if="tableOptions.open">
                <el-table :data="tableOptions.tableData" stripe border size="small"
                         header-cell-class-name="table_header"
                        cell-class-name="table_cell" style="width: 100%">
                    <el-table-column type="index" label="序号" width="50"/>
                    <template v-if="tableOptions.items">
                        <template v-for="item in tableOptions.items">
                            <el-table-column v-if="item.type != Search_Type.Select" :prop="item.name" :label="item.label"/>
                            <el-table-column v-else :label="item.label">
                                <template slot-scope="scope">
    <template v-if="item.props">
        <template v-if="item.props[scope.row[item.name]]">
            <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                    {{item.props[scope.row[item.name]]}}
                </el-tag>
            </template>
            <template v-else>
                {{item.props[scope.row[item.name]]}}
            </template>
        </template>
        <template v-else>
            <el-tag type="danger" disable-transitions>
                {{scope.row[item.name]}}
            </el-tag>
        </template>
    </template>
    <template v-else>
        <el-tag type="warning" disable-transitions>
            {{scope.row[item.name]}}
        </el-tag>
    </template>
</template>
                            </el-table-column>
                        </template>
                    </template>
                    <el-table-column min-width="250" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" @click="selectHandle(scope.row)">
                                选择 <i class="el-icon-check"></i>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 20, 50, 100]"
                        :current-page="tableOptions.page"
                        :page-size="tableOptions.limit"
                        :total="tableOptions.total">
                </el-pagination>
            </template>
        </el-main>
    </el-container>
</template>

<script>
    import SearchForm from "../common/OptionalSearchForm";
    import {Search_Type} from "../../constants";
    const prefix = '/template';
    const getListUrl = `${prefix}/list`;
    export default {
        components: {SearchForm},
        data(){
            return{
                Search_Type,
                tableOptions:{
                    tableData:[],
                    page: 1,
                    limit: 10,
                    total: 0,
                },
                searchOptions:{
                    searchForm: {},
                    searchParams:{},
                },
            }
        },
        props:['params'],
        created() {
            this.g_setupMetadataConfig(this, 'Menu_Template')
            if(!this.params){
                this.getTableData();
            }
        },
        methods: {
            getTableData(){
                this.$axios.get(getListUrl,{
                    params: {
                        ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
                        ...this.searchOptions.searchForm
                    }
                }).then(res=>{
                    this.tableOptions.tableData = res.data.records;
                    this.tableOptions.page = res.data.current;
                    this.tableOptions.limit = res.data.size;
                    this.tableOptions.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.tableOptions.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.tableOptions.page = page;
                this.getTableData();
            },
            handleSearch(){
                this.tableOptions.page = 1;
                this.getTableData();
            },
            selectHandle(row){
                this.$emit('onselect', row)
            }
        }
    }
</script>

<style scoped>

</style>